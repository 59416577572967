<template>
  <section class="card">
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header" >
        <span class="selecionado" v-html="selecionado">
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
        <template slot="buttons">
          <div class="row actions mb-3">
            <div class="col-12 text-center">
              <div class="input-group">
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-search"></i>
                  </span>
                </span>
                <input type="text" class="form-control form-control-sm" v-model="filter" @keyup="filterChanged">
              </div>
            </div>
          </div>
        </template>
        <template slot="custom">
          <table class="table table-sm">
            <tbody>
              <template v-for="item in resultFiltered">
                <tr :key="item.Id" v-if="resultFiltered.length">
                  <td  style="width:50px">
                    <div class="radio-custom radio-primary">
                      <input type="radio"
                             v-model="selected"
                             class="check-table"
                             :value="item"
                             @change="changeCheckboxCallback(item)"
                             :id="'estoque' + item.Id">
                      <label style="display:block" :for="'estoque' + item.Id">{{ item.Nome }}</label>
                    </div>
                  </td>
                </tr>
              </template>
              
              <tr>
                <td>
                  <p v-if="!resultFiltered.length" class="text-center">Sem resultados</p>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

      </filtro-component>
      <div :class="'h-100 ' + (filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')" v-if="result.length">
        <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
          <div class="h-100">
            <div class="table-responsive h-100">
              <table class="table table-bordered table-striped table-hover mb-0 table-sm">
                <thead>
                  <tr>
                    <th class="text-center">Módulo</th>
                    <th>Tela ou Ação</th>
                    <th>Descrição</th>
                    <th class="text-center">
                      <div class="checkbox-custom checkbox-primary" style="display:inline-block">
                        <input type="checkbox"
                               :checked="permissao.length == result.map(c => c.length).reduce((a,b) => a+b, 0)"
                               class="check-table"
                               id="checkall"
                               @change="checkall">
                        <label for="checkall"></label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(controller, $index) in result">
                    <tr :key="$index">
                      <td class="text-center" style="vertical-align:middle" :rowspan="controller.length + 1"> {{ controller[0].Controller }}</td>
                    </tr>
                    <template v-for="action in controller">
                      <tr :key="action.Action" @click="rowClick(action)">
                        <td>
                          {{action.Action}}
                        </td>
                        <td>
                          {{action.Descricao}}
                        </td>
                        <td class="text-center">
                          <div class="checkbox-custom checkbox-primary" style="display:inline-block">
                            <input type="checkbox"
                                   v-model="permissao"
                                   class="check-table"
                                   :value="action"
                                   :id="'permissao' + (action.Action + '-' + action.Controller)">
                            <label :for="'permissao' + (action.Action + '-' + action.Controller)"></label>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <div v-if="!result.length">
        <h3 style="    left: 45%;
            position: absolute;
            top: 55%; margin:0!important">
          Selecione um perfil ou usuário ao lado.
        </h3>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';
import consultar from '@/services/consultar.js';

//permissoes
export default {

    components: {
        ...consultar.components,

    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/configuracoes/permissoes',
            clone: '',
            obj: {},
            actionButtons: [

                {
                    text: 'Salvar alterações',
                    className: 'salvar padrao',
                    icon: 'fas fa-save',
                    color: 'primary',
                    visible: false,
                    title: 'Salvar alterações',
                    callback: that.salvar
                },
                {
                    text: 'Cancelar',
                    className: 'btn-cancelar padrao',
                    icon: '',
                    iconColor: '',
                    color: 'danger',
                    visible: false,
                    title: 'Cancelar alterações',
                    callback: that.cancelar
                },
            ],
            filtros:
            {
                visao: [
                    {
                        id: 0,
                        title: 'Usuários',
                        filtro: [
                            {
                                id: 'isUser',
                                value: true,
                            },
                        ]
                    },
                    {
                        id: 1,
                        title: 'Perfis',
                        filtro:
                            [
                                {
                                    id: 'isUser',
                                    value: false,
                                },
                            ]
                    }
                ],
                visaoSelecionada: 1,
                visible: true
            },
            tipo: 'Usuários',
            resultFiltered: [],
            permissao: [],
            filter: '',
            usuarios: [],
            perfis: [],
            itemSelecionado: [0]
            
        };
    },

    methods: {
        ...consultar.methods,
        rowClick: function (action) {
            if (this.permissao.some(i => i.Action === action.Action && i.Controller === action.Controller))
                this.permissao.splice(this.permissao.indexOf(action), 1);
            else
                this.permissao.push(action);
        },
        checkall: function () {
            if (this.permissao.length === this.result.map(c => c.length).reduce((a, b) => a + b, 0))
                this.permissao = []
            else {
                this.permissao = []
                for (let i in this.result) {
                    for (let j in this.result[i]) {
                        this.permissao.push(this.result[i][j]);
                    }
                }
            }

        },
        changeCheckboxCallback: function (item) {
            this.resetSelection();
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
            let isUser = visao.filtro[0].value;
            let that = this;
            api.get(`/configuracoes/permissoes?Id=${item.Id}&IsUser=${isUser}`)
                .then(response => {
                    console.log(response.data);
                    if (response) {
                        that.result = response.data;
                        that.selecionado = `Permissões de ${visao.title}: <b>${item.Nome}</b>`;
                        for (let i in that.result) {
                            var filteredPerm = that.result[i].filter(f => f.PermissaoAssociada);
                            for (var j in filteredPerm) {
                                if (filteredPerm[j]) {
                                    that.permissao.push(filteredPerm[j]);
                                }
                            }
                        }
                        for (let i in that.actionButtons) {
                            that.actionButtons[i].visible = true;
                        }

                    }
                });

        },
        salvar: function () {
            let that = this;
            this.$root.errors = [];
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
            let isUser = visao.filtro[0].value;

            api
                .post('/configuracoes/permissoes', { Id: this.selected.Id, model: this.permissao, isUser: String(isUser) })
                .then(response => {
                    if (response.data.success) {
                        that.cancelar();
                    }
                });

        },
        cancelar: function () {
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];

            this.resetSelection();
            this.selected = [];

        },
        
        resetSelection: function () {
            this.permissao = [];
            this.result = [];
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];

            this.selecionado = 'Permissões de <b>'+visao.title+'</b>';
            for (var i in this.actionButtons) {
                this.actionButtons[i].visible = false;
            }
        },
        filterChanged: function () {
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
            let isUser = visao.filtro[0].value;

            if (isUser) {
                if (this.filter) {
                    this.resultFiltered = this.usuarios.filter(f => f.Nome.toLowerCase().includes(this.filter.toLowerCase()));
                }
                else {
                    this.resultFiltered = this.usuarios;
                }
            }
            else {
                if (this.filter) {
                    this.resultFiltered = this.perfis.filter(f => f.Nome.toLowerCase().includes(this.filter.toLowerCase()));
                }
                else {
                    this.resultFiltered = this.perfis;
                }
            }
        },
        handleTrocarVisao: function () {
            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
            let isUser = visao.filtro[0].value;

            this.cancelar();
            this.filter = '';

            if (isUser) {
                this.resultFiltered = this.usuarios;
            }
            else {
                this.resultFiltered = this.perfis;
            }
            debugger;
            this.selecionado = `Permissões de <b>${visao.title}</b>`;

        },
        
    },
    mounted: function () { },

    beforeMount: function () {
        let that = this;
        api.get(this.url)
            .then(response => {
                let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
                let isUser = visao.filtro[0].value;

                that.usuarios = response.data.Usuarios;
                that.perfis = response.data.Perfis;

                
                that.resultFiltered = isUser ? response.data.Usuarios : response.data.Perfis;
                that.selecionado = `Permissões de <b>${visao.title}</b>`;
                
            });
    }
};
</script>
